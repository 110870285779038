// FID Berlin

@import "../_kflvariables";

$fid-link-color: #a51e37;

a:link, a:visited {
    color: $fid-link-color;
}

#content-header {
    background-image: url("/++theme++kfl-theme/images/Banner_FID_Berlin.jpg") ;
    background-repeat: no-repeat;
    background-position: center;

    #portal-slogan {
        color: $kfl-header-bgcolor;
    }
}

#mainnavigation-wrapper #kfl-user-box #kfl-anontools a {
    color: $fid-link-color;
}